<template>
  <div
    class="add-new-template-setting w-100 h-100"
    @click="enableEditModeAndStop($event)"
  >
    <div v-if="mode == ADD_MODE" class="d-flex align-center w-100 h-100 pl-4">
      {{ $localizationService.localize("drp_import.stepper.template.new_btn") }}
    </div>
    <div v-else class="d-flex align-center template-padding w-100 h-100">
      <div
        v-if="mode == EDIT_MODE"
        class="w-100 h-100 d-flex align-center template-in-select-padding pr-2"
        @click.stop=""
      >
        <v-text-field
          @click="disableActivatedFocus()"
          v-model="template.name"
          autofocus
          :label="
            $localizationService.localize(
              'drp_import.stepper.template.param.name'
            )
          "
        ></v-text-field>

        <v-icon
          class="ml-2"
          color="green"
          @click.stop="saveTemplate()"
          :disabled="!template.name"
          >mdi-check</v-icon
        >
        <v-icon class="ml-2" color="red" @click.stop="close()"
          >mdi-close</v-icon
        >
      </div>

      <div v-if="mode == VIEW_MODE" class="template-in-select-padding">
        <span>{{ template.name }}</span>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="mode == VIEW_MODE"
        class="ml-2"
        icon
        @click.stop="enableEditMode()"
        :disabled="pLoading"
      >
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
      <v-btn
        v-if="template && template.id"
        icon
        color="red"
        @click.stop="openDeleteTemplateDialog(template)"
        :disabled="pLoading"
      >
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </div>
    <ConfirmationDialog ref="confirmationDialog" />
  </div>
</template>

<script>
import Vue from "vue";

const locService = Vue.prototype.$localizationService;

const ADD_MODE = "add";
const EDIT_MODE = "edit";
const VIEW_MODE = "view";

export default {
  name: "TemplateComponent",

  components: {
    ConfirmationDialog: () => import("../../ConfirmationDialog.vue"),
  },

  props: {
    pTemplate: Object,
    pLoading: Boolean,
  },

  watch: {
    pTemplate() {
      this.template = this.pTemplate ? { ...this.pTemplate } : undefined;
    },
  },

  data() {
    return {
      ADD_MODE,
      EDIT_MODE,
      VIEW_MODE,

      mode: undefined,

      template: this.pTemplate ? { ...this.pTemplate } : undefined,
    };
  },

  methods: {
    enableEditModeAndStop(event) {
      if (this.mode == ADD_MODE) {
        this.enableEditMode();
        event.stopPropagation();
      }
    },

    enableEditMode() {
      if (this.mode == VIEW_MODE) {
        this.disableActivatedFocus();
        this.mode = EDIT_MODE;
        return;
      }

      if (this.mode == ADD_MODE) {
        this.template = {
          name: "",
        };

        this.mode = EDIT_MODE;
      }
    },

    saveTemplate() {
      this.$emit("onSaveTemplate", { ...this.template });
      this.closeEditMode();
    },

    close() {
      if (this.pTemplate) {
        this.template.name = this.pTemplate.name;
      }
      this.closeEditMode();
    },

    closeEditMode() {
      this.disableActivatedFocus();

      if (this.template && this.template.id) {
        this.mode = VIEW_MODE;
      } else {
        this.mode = ADD_MODE;
        this.template.name = "";
      }
    },

    disableActivatedFocus() {
      this.$emit("onDeactivateFocus");
    },

    openDeleteTemplateDialog(template) {
      this.disableActivatedFocus();
      this.$refs.confirmationDialog.openDialog({
        header: locService.localize(
          "drp_import.stepper.template.dialog.delete.title",
        ),

        text: locService.localize(
          "drp_import.stepper.template.dialog.delete.text",
          [template.name],
        ),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => this.$emit("onDeleteTemplate", template),
      });
    },
  },

  mounted() {
    if (this.template) {
      this.mode = VIEW_MODE;
    } else {
      this.mode = ADD_MODE;
    }
  },
};
</script>

<style lang="scss">
.template-settings {
  .template-padding {
    padding-right: 3.5em;
  }
  .v-input__append-inner {
    z-index: 999999 !important;
  }
}
.v-list-item {
  .template-in-select-padding {
    padding-left: 1em;
  }
}
.add-new-template-setting {
  position: absolute !important;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999 !important;
  .v-label--active {
    display: none !important;
  }
  .v-input {
    padding: 0;
    margin: 0;
    .v-input__slot {
      margin: 0;
    }
  }
  .v-text-field__details {
    display: none !important;
  }
}
</style>
